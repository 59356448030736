<template>
    <div class="EmpBasic">
        <div class="Btns">
            <div>
                <div>
                    <el-input 
                        v-model="empName" 
                        placeholder="请输入员工名进行搜索..." 
                        prefix-icon="el-icon-search" 
                        style="width:300px;margin-right:8px"
                        @keydown.enter.native="initEmps"
                        clearable
                        @clear="initEmps"></el-input>
                    <el-button type="primary" icon="el-icon-search" @click="initEmps">搜索</el-button>
                    <el-button type="primary" @click="showAdvancedSearchVisible = !showAdvancedSearchVisible">
                        <i :class="showAdvancedSearchVisible?'fa fa-angle-double-up':'fa fa-angle-double-down'" aria-hidden="true"></i>  高级搜索
                    </el-button>
                </div>
            </div>
            <div>
                <el-upload
                        class="upLoad"
                        action="/employee/basic/import"
                        :headers="headers"
                        :show-file-list="false"
                        :before-upload="beforeUpload"
                        :on-success="onSuccess"
                        :on-error="onError"
                        :disabled="importDataDisabled"
                        >
                    <el-button type="success" :icon="importDataBtnIcon" :disabled="importDataDisabled">
                        {{importDataBtnText}}
                    </el-button>
                </el-upload>
                <el-button type="success" @click="exportData" icon="el-icon-download">
                    导出数据
                </el-button>
                <el-button type="primary" icon="el-icon-plus" @click="showAddEmpView">添加员工</el-button>
            </div>
        </div>
        <div>
            <transition name="slide-fade">
                <div class="advancedSearch" v-if="showAdvancedSearchVisible">
                    <el-row>
                        <el-col :span="5">
                            政治面貌:
                            <el-select v-model="searchValue.politicId" style="width:130px;" size="mini" placeholder="政治面貌">
                                <el-option
                                    v-for="item in politicsStatus"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="4">
                            民族:
                            <el-select v-model="searchValue.nationId" style="width:130px;" size="mini" placeholder="民族">
                                <el-option
                                    v-for="item in nations"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="4">
                            职位:
                            <el-select v-model="searchValue.posId" style="width:130px;" size="mini" placeholder="职位">
                                <el-option
                                    v-for="item in positions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="4">
                            职称:
                            <el-select v-model="searchValue.jobLevelId" style="width:130px;" size="mini" placeholder="职称">
                                <el-option
                                    v-for="item in jobLevels"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="7">
                            聘用形式：
                            <el-radio-group v-model="searchValue.engageForm">
                                <el-radio label="劳动合同">劳动合同</el-radio>
                                <el-radio label="劳务合同">劳务合同</el-radio>
                            </el-radio-group>
                        </el-col>
                    </el-row>
                    <el-row style="margin-top:8px">
                        <el-col :span="5">
                            所属部门:
                            <el-popover
                                placement="bottom"
                                title="请选择部门"
                                width="230"
                                trigger="manual"
                                v-model="visible2">
                                <el-tree 
                                    :data="allDeps" 
                                    :props="defaultProps"
                                    default-expand-all
                                    @node-click="searchHandleDepNodeClick"></el-tree>
                                <div slot="reference" class="departmentAdvanced" @click="showDepView2">
                                    {{inputDepName}}
                                </div>
                            </el-popover>
                        </el-col>
                        <el-col :span="7">
                            入职日期:
                            <el-date-picker
                                v-model="searchValue.beginDateScope"
                                size="mini"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="7" :offset="1">
                            转正日期:
                            <el-date-picker
                                v-model="searchValue.conversionTimeScope"
                                size="mini"
                                type="daterange"
                                value-format="yyyy-MM-dd"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                            </el-date-picker>
                        </el-col>
                        <el-col :span="3" :offset="1">
                            <el-button size="mini" @click="clearAllSearchData">取消</el-button>
                            <el-button size="mini" type="primary" icon="el-icon-search"  @click="initEmps('advanced')">搜索</el-button>
                        </el-col>
                    </el-row>
                </div>
            </transition>
        </div>
        <div style="margin-top:15px">
            <el-table
                :data="emps"
                stripe
                border
                v-loading="loading"
                element-loading-text="拼命加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.8)"
                style="width: 100%">
                <el-table-column
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="姓名"
                    fixed="left"
                    align="left"
                    width="60">
                </el-table-column>
                <el-table-column
                    prop="workID"
                    label="工号"
                    align="center"
                    width="85">
                </el-table-column>
                <el-table-column
                    prop="gender"
                    label="性别"
                    align="center"
                    width="50">
                </el-table-column>
                <el-table-column
                    prop="birthday"
                    label="出生日期"
                    align="center"
                    width="95">
                </el-table-column>
                <el-table-column
                    prop="idCard"
                    label="身份证号"
                    align="left"
                    width="150">
                </el-table-column>
                <el-table-column
                    prop="wedlock"
                    label="婚姻状况"
                    align="center"
                    width="70">
                </el-table-column>
                <el-table-column
                    prop="nation.name"
                    label="民族"
                    align="center"
                    width="50">
                </el-table-column>
                <el-table-column
                    prop="nativePlace"
                    label="籍贯"
                    align="center"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="politicsStatus.name"
                    label="政治面貌"
                    align="center"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="email"
                    label="邮箱"
                    align="center"
                    width="188">
                </el-table-column>
                <el-table-column
                    prop="phone"
                    label="电话号码"
                    align="center"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="address"
                    label="联系地址"
                    align="left"
                    width="270">
                </el-table-column>
                <el-table-column
                    prop="department.name"
                    label="部门"
                    align="center"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="position.name"
                    label="职位"
                    align="center"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="joblevel.name"
                    label="职称"
                    align="center"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="engageForm"
                    label="聘用形式"
                    align="center"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="tiptopDegree"
                    label="最高学历"
                    align="center"
                    width="80">
                </el-table-column>
                <el-table-column
                    prop="school"
                    label="毕业院校"
                    align="center"
                    width="150">
                </el-table-column>
                <el-table-column
                    prop="specialty"
                    label="专业"
                    align="center"
                    width="150">
                </el-table-column>
                <el-table-column
                    prop="workState"
                    label="在职状态"
                    align="center"
                    width="70">
                </el-table-column>
                <el-table-column
                    prop="beginDate"
                    label="入职日期"
                    align="center"
                    width="95">
                </el-table-column>
                <el-table-column
                    prop="conversionTime"
                    label="转正日期"
                    align="center"
                    width="95">
                </el-table-column>
                <el-table-column
                    prop="beginContract"
                    label="合同起始日期"
                    align="center"
                    width="95">
                </el-table-column>
                <el-table-column
                    prop="endContract"
                    label="合同截至日期"
                    align="center"
                    width="95">
                </el-table-column>
                <el-table-column
                    label="合同期限"
                    align="center"
                    width="100">
                    <template slot-scope="scope">
                        <el-tag>{{scope.row.contractTerm}}</el-tag>年
                    </template>
                </el-table-column>
                <el-table-column
                    label="操作"
                    fixed="right"
                    width="200">
                    <template slot-scope="scope">
                        <el-button style="padding:3px" size="mini" @click="showEditEmpView(scope.row)">编辑</el-button>
                        <el-button style="padding:3px" size="mini" type="primary">查看高级资料</el-button>
                        <el-button style="padding:3px" size="mini" type="danger" @click="deleteEmp(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page">
                <el-pagination
                    background
                    @current-change="currentChange"
                    @size-change="sizeChange"
                    layout="sizes, prev, pager, next, jumper, ->, total"
                    :total="total">
                </el-pagination>
            </div>
        </div>
        <el-dialog
            :title="title"
            @close="visible=false"
            :visible.sync="dialogVisible"
            width="80%">
            <div>
                <el-form ref="empForm" :model="emp" :rules="rules">
                  <el-row>
                      <el-col :span="6">
                          <el-form-item label="姓名:" prop="name">
                              <el-input 
                                v-model="emp.name" 
                                size="mini" 
                                style="width:150px;" 
                                placeholder="请输入员工姓名"
                                prefix-icon="el-icon-edit"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="5">
                          <el-form-item label="性别:" prop="gender">
                              <el-radio-group v-model="emp.gender">
                                  <el-radio label="男">男</el-radio>
                                  <el-radio label="女">女</el-radio>
                              </el-radio-group>
                          </el-form-item>
                      </el-col>
                      <el-col :span="6">
                          <el-form-item label="出生日期:" prop="birthday">
                                <el-date-picker
                                    v-model="emp.birthday"
                                    type="date"
                                    size="mini"
                                    style="width:150px;"
                                    value-format="yyyy-MM-dd"
                                    placeholder="出生日期">
                                </el-date-picker>
                          </el-form-item>
                      </el-col>
                      <el-col :span="7">
                          <el-form-item label="政治面貌:" prop="politicId">
                                <el-select v-model="emp.politicId" style="width:200px;" size="mini" placeholder="政治面貌">
                                    <el-option
                                        v-for="item in politicsStatus"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                          </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="6">
                          <el-form-item label="民族:" prop="nationId">
                            <el-select v-model="emp.nationId" style="width:150px;" size="mini" placeholder="民族">
                                <el-option
                                    v-for="item in nations"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :span="5">
                          <el-form-item label="籍贯:" prop="nativePlace">
                              <el-input 
                                size="mini" 
                                v-model="emp.nativePlace" 
                                placeholder="请输入籍贯" 
                                prefix-icon="el-icon-edit"
                                style="width:120px;"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="6">
                          <el-form-item label="电子邮箱:" prop="email">
                              <el-input 
                                size="mini" 
                                v-model="emp.email" 
                                placeholder="请输入电子邮箱" 
                                prefix-icon="el-icon-message"
                                style="width:150px;"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="7">
                          <el-form-item label="联系地址:" prop="address">
                              <el-input 
                                size="mini" 
                                v-model="emp.address" 
                                placeholder="请输入联系地址" 
                                prefix-icon="el-icon-edit"
                                style="width:200px;"></el-input>
                          </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="6">
                          <el-form-item label="职位:" prop="posId">
                            <el-select v-model="emp.posId" style="width:150px;" size="mini" placeholder="职位">
                                <el-option
                                    v-for="item in positions"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :span="5">
                          <el-form-item label="职称:" prop="jobLevelId">
                              <el-select v-model="emp.jobLevelId" style="width:150px;" size="mini" placeholder="职称">
                                <el-option
                                    v-for="item in jobLevels"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :span="6">
                          <el-form-item label="所属部门:" prop="departmentId">
                                <el-popover
                                    placement="bottom"
                                    title="请选择部门"
                                    width="250"
                                    trigger="manual"
                                    v-model="visible">
                                    <el-tree 
                                        :data="allDeps" 
                                        :props="defaultProps"
                                        default-expand-all
                                        @node-click="handleDepNodeClick"></el-tree>
                                    <div slot="reference" class="department" @click="showDepView">
                                        {{inputDepName}}
                                    </div>
                                </el-popover>                               
                          </el-form-item>
                      </el-col>
                      <el-col :span="7">
                          <el-form-item label="电话号码:" prop="phone">
                              <el-input 
                                size="mini" 
                                v-model="emp.phone" 
                                placeholder="请输入电话号码" 
                                prefix-icon="el-icon-phone"
                                style="width:200px;"></el-input>
                          </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="6">
                          <el-form-item label="工号:" prop="workID">
                            <el-input 
                                size="mini" 
                                v-model="emp.workID" 
                                placeholder="请输入工号" 
                                prefix-icon="el-icon-edit"
                                disabled
                                style="width:150px;"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="5">
                          <el-form-item label="学历:" prop="tiptopDegree">
                              <el-select v-model="emp.tiptopDegree" style="width:150px;" size="mini" placeholder="学历">
                                <el-option
                                    v-for="item in tiptopDegrees"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                </el-option>
                            </el-select>
                          </el-form-item>
                      </el-col>
                      <el-col :span="6">
                          <el-form-item label="毕业院校:" prop="school">
                              <el-input 
                                size="mini" 
                                v-model="emp.school" 
                                placeholder="请输入毕业院校" 
                                prefix-icon="el-icon-edit"
                                style="width:150px;"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="7">
                          <el-form-item label="专业名称:" prop="specialty">
                              <el-input 
                                size="mini" 
                                v-model="emp.specialty" 
                                placeholder="请输入专业名称" 
                                prefix-icon="el-icon-edit"
                                style="width:200px;"></el-input>
                          </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="6">
                          <el-form-item label="入职日期:" prop="beginDate">
                            <el-date-picker
                                v-model="emp.beginDate"
                                type="date"
                                size="mini"
                                style="width:130px;"
                                value-format="yyyy-MM-dd"
                                placeholder="入职日期">
                            </el-date-picker>
                          </el-form-item>
                      </el-col>
                      <el-col :span="5">
                          <el-form-item label="转正日期:" prop="conversionTime">
                              <el-date-picker
                                v-model="emp.conversionTime"
                                type="date"
                                size="mini"
                                style="width:130px;"
                                value-format="yyyy-MM-dd"
                                placeholder="转正日期">
                            </el-date-picker>
                          </el-form-item>
                      </el-col>
                      <el-col :span="6">
                          <el-form-item label="合同起始日期:" prop="beginContract">
                              <el-date-picker
                                v-model="emp.beginContract"
                                type="date"
                                size="mini"
                                style="width:130px;"
                                value-format="yyyy-MM-dd"
                                placeholder="合同起始日期">
                            </el-date-picker>
                          </el-form-item>
                      </el-col>
                      <el-col :span="7">
                          <el-form-item label="合同截止日期:" prop="endContract">
                              <el-date-picker
                                v-model="emp.endContract"
                                type="date"
                                size="mini"
                                style="width:130px;"
                                value-format="yyyy-MM-dd"
                                placeholder="合同截止日期">
                            </el-date-picker>
                          </el-form-item>
                      </el-col>
                  </el-row>
                  <el-row>
                      <el-col :span="8">
                          <el-form-item label="身份证号码:" prop="idCard">
                            <el-input 
                                size="mini" 
                                v-model="emp.idCard" 
                                placeholder="请输入身份证号" 
                                prefix-icon="el-icon-edit"
                                style="width:180px;"></el-input>
                          </el-form-item>
                      </el-col>
                      <el-col :span="8">
                          <el-form-item label="聘用形式:" prop="engageForm">
                            <el-radio-group v-model="emp.engageForm">
                                <el-radio label="劳动合同">劳动合同</el-radio>
                                <el-radio label="劳务合同">劳务合同</el-radio>
                            </el-radio-group>
                          </el-form-item>
                      </el-col>
                      <el-col :span="8">
                          <el-form-item label="婚姻状况:" prop="wedlock">
                            <el-radio-group v-model="emp.wedlock">
                                <el-radio label="已婚">已婚</el-radio>
                                <el-radio label="未婚">未婚</el-radio>
                                <el-radio label="离异">离异</el-radio>
                            </el-radio-group>
                          </el-form-item>
                      </el-col>
                  </el-row>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="doAddEmp">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name:"EmpBasic",
    data () {
        return {
            searchValue:{
                politicId: null,
                nationId: null,
                posId: null,
                jobLevelId: null,
                engageForm: '',
                departmentId: null,
                beginDateScope: null,
                conversionTimeScope: null
            },
            visible2:false,
            showAdvancedSearchVisible:false,
            headers:{
                Authorization:window.sessionStorage.getItem('tokenStr')
            },
            importDataBtnText:'导入数据',
            importDataBtnIcon:'el-icon-upload2',
            importDataDisabled:false,
            id:null,
            title:'',
            emps:[],
            total:0,
            loading:false,
            currentPage:1,
            size:10,
            empName:'',
            dialogVisible:false,
            nations:[],
            jobLevels:[],
            politicsStatus:[],
            positions:[],
            tiptopDegrees:['博士','硕士','本科','大专','高中','初中','小学','其他'],
            visible:false,
            defaultProps: {
                children: 'children',
                label: 'name'
            },
            allDeps:[],
            inputDepName:'',
            emp:{
                name: '',
                gender: '',
                birthday: '',
                idCard: '',
                wedlock: '',
                nationId: null,
                nativePlace: '',
                politicId: null,
                email: '',
                phone: '',
                address: '',
                departmentId: null,
                jobLevelId: null,
                posId: null,
                engageForm: '',
                tiptopDegree: '',
                specialty: '',
                school: '',
                beginDate: '',
                workState: '在职',
                workID: '',
                contractTerm: null,
                conversionTime: '',
                notWorkDate: null,
                beginContract: '',
                endContract: '',
                workAge: null,
                salaryId: null
            },
            rules:{
                name: [{ required: true, message: "请输入员工姓名", trigger: "blur" }],
                gender: [{ required: true, message: "请选择员工性别", trigger: "blur" }],
                birthday: [{ required: true, message: "请选择出生日期", trigger: "blur" }],
                idCard: [{ required: true, message: "请输入身份证号", trigger: "blur" },
                    { pattern:/^(([1-9][0-9]{5}(19|20)[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}([0-9]|X|x))|([1-9][0-9]{5}[0-9]{2}((0[1-9])|(1[0-2]))([0-2][1-9]|10|20|30|31)[0-9]{3}))$/, 
                    message: "身份证号不正确", 
                    trigger: "blur" }
                ],
                wedlock: [{ required: true, message: "请选择婚姻状况", trigger: "blur" }],
                nationId: [{ required: true, message: "请选择民族", trigger: "blur" }],
                nativePlace: [{ required: true, message: "请输入籍贯", trigger: "blur" }],
                politicId: [{ required: true, message: "请选择政治面貌", trigger: "blur" }],
                email: [{ required: true, message: "请输入电子邮箱", trigger: "blur" },{
                    type:'email',message:'邮箱地址格式不正确',trigger:'blur'
                }],
                phone: [{ required: true, message: "请输入电话号码", trigger: "blur" }],
                address: [{ required: true, message: "请输入员工地址", trigger: "blur" }],
                departmentId: [{ required: true, message: "请选择所属部门", trigger: "blur" }],
                jobLevelId: [{ required: true, message: "请选择员工职称", trigger: "blur" }],
                posId: [{ required: true, message: "请选择员工职位", trigger: "blur" }],
                engageForm: [{ required: true, message: "请选择聘用形式", trigger: "blur" }],
                tiptopDegree: [{ required: true, message: "请选择员工学历", trigger: "blur" }],
                specialty: [{ required: true, message: "请输入员工专业", trigger: "blur" }],
                school: [{ required: true, message: "请输入毕业院校", trigger: "blur" }],
                beginDate: [{ required: true, message: "请选择入职日期", trigger: "blur" }],
                workState: [{ required: true, message: "请输入工作状态", trigger: "blur" }],
                contractTerm: [{ required: true, message: "请输入合同期限", trigger: "blur" }],
                notWorkDate: [{ required: true, message: "请选择离职日期", trigger: "blur" }],
                beginContract: [{ required: true, message: "请选择合同起始日期", trigger: "blur" }],
                endContract: [{ required: true, message: "请选择合同结束日期", trigger: "blur" }],
                conversionTime:[{ required: true, message: "请选择转正日期", trigger: "blur" }],
                workID:[{ required: true, message: "请输入工号", trigger: "blur" }]
            }
        };
    },
    mounted(){
        this.initEmps();
        this.initDatas();
        this.initPositions();
    },
    methods: {
        clearAllSearchData(){
            this.searchValue = {
                politicId: null,
                nationId: null,
                posId: null,
                jobLevelId: null,
                engageForm: '',
                departmentId: null,
                beginDateScope: null,
                conversionTimeScope: null
            };
            this.inputDepName = '';
            this.initEmps();
        },
        onSuccess(){
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataBtnText = '导入数据';
            this.importDataDisabled = false;
            this.$message.success('数据导入成功');
            this.initEmps();
        },
        onError(){
            this.importDataBtnIcon = 'el-icon-upload2';
            this.importDataBtnText = '导入数据';
            this.importDataDisabled = false;
            this.$message.error();('数据导入失败');
        },
        beforeUpload(){
            this.importDataBtnIcon = 'el-icon-loading';
            this.importDataBtnText = '正在导入';
            this.importDataDisabled = true;
        },
        deleteEmp(data){
            this.$confirm('此操作将永久删除['+data.name+']员工, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.deleteRequest('/employee/basic/' + data.id).then(res=>{
                            if(res){
                                if(this.total%this.size == 0){
                                    if(this.currentPage > 1){
                                        this.currentPage = this.currentPage - 1;
                                    }
                                }
                                this.initEmps();
                            }
                        });
                    }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });          
            });
        },  
        doAddEmp(){
            if(this.emp.id){
                this.$refs['empForm'].validate(valid=>{
                    if(valid){
                        this.putRequest('/employee/basic/',this.emp).then(res=>{
                            if(res){
                                this.dialogVisible = false;
                                this.initEmps();
                            }
                        });
                    }
                });
            }else{
                this.$refs['empForm'].validate(valid=>{
                    if(valid){
                        this.postRequest('/employee/basic/',this.emp).then(res=>{
                            if(res){
                                this.dialogVisible = false;
                                this.initEmps();
                            }
                        });
                    }
                });
            }
        },
        handleDepNodeClick(data){
            this.inputDepName = data.name;
            this.emp.departmentId = data.id;
            this.visible = !this.visible;
        },
        showDepView(){
            this.visible2 = false;
            this.visible = !this.visible;
        },
        getMaxWorkID(){
            this.getRequest('/employee/basic/maxWorkID').then(res=>{
                if(res){
                    this.emp.workID = res.data;
                }
            })
        },
        initPositions(){
            this.getRequest('/employee/basic/positions').then(res=>{
                if(res){
                    this.positions = res;
                }
            })
        },
        initDatas(){
            if(!window.sessionStorage.getItem('nations')){
                this.getRequest('/employee/basic/nations').then(res=>{
                    if(res){
                        this.nations = res;
                        window.sessionStorage.setItem('nations',JSON.stringify(res));
                    }
                })
            }else{
                this.nations = JSON.parse(window.sessionStorage.getItem('nations'));
            }
            if(!window.sessionStorage.getItem('jobLevels')){
                this.getRequest('/employee/basic/joblevels').then(res=>{
                    if(res){
                        this.jobLevels = res;
                        window.sessionStorage.setItem('jobLevels',JSON.stringify(res));
                    }
                })
            }else{
                this.jobLevels = JSON.parse(window.sessionStorage.getItem('jobLevels'));
            }
            if(!window.sessionStorage.getItem('politicsStatus')){
                this.getRequest('/employee/basic/politicsstatus').then(res=>{
                    if(res){
                        this.politicsStatus = res;
                        window.sessionStorage.setItem('politicsStatus',JSON.stringify(res));
                    }
                })
            }else{
                this.politicsStatus = JSON.parse(window.sessionStorage.getItem('politicsStatus'));
            }
            if(!window.sessionStorage.getItem('allDeps')){
                this.getRequest('/system/basic/department/').then(res=>{
                    if(res){
                        this.allDeps = res;
                        window.sessionStorage.setItem('allDeps',JSON.stringify(res));
                    }
                })
            }else{
                this.allDeps = JSON.parse(window.sessionStorage.getItem('allDeps'));
            }
        },
        showAddEmpView(){
            this.title = '添加员工';
            this.getMaxWorkID();
            this.initPositions();
            this.dialogVisible = true;
            this.inputDepName = '';
            this.emp = {
                id:null,
                name: '',
                gender: '',
                birthday: '',
                idCard: '',
                wedlock: '',
                nationId: null,
                nativePlace: '',
                politicId: null,
                email: '',
                phone: '',
                address: '',
                departmentId: null,
                jobLevelId: null,
                posId: null,
                engageForm: '',
                tiptopDegree: '',
                specialty: '',
                school: '',
                beginDate: '',
                workState: '在职',
                workID: '',
                contractTerm: null,
                conversionTime: '',
                notWorkDate: null,
                beginContract: '',
                endContract: '',
                workAge: null,
                salaryId: null
            };
        },
        showEditEmpView(data){
            this.title = '编辑员工信息';
            this.emp = data;
            this.inputDepName = data.department.name;
            this.dialogVisible = true;
        },
        sizeChange(size){
            this.size = size;
            this.initEmps();
        },
        currentChange(currentPage){
            this.currentPage = currentPage;
            this.initEmps();
        },
        initEmps(type){
            this.loading = true;
            let url = '/employee/basic/?currentPage='+this.currentPage+'&size='+this.size;
            if(type && type == 'advanced'){
                if(this.searchValue.politicId){
                    url += '&politicId='+this.searchValue.politicId;
                }
                if(this.searchValue.nationId){
                    url += '&nationId='+this.searchValue.nationId;
                }
                if(this.searchValue.posId){
                    url += '&posId='+this.searchValue.posId;
                }
                if(this.searchValue.jobLevelId){
                    url += '&jobLevelId='+this.searchValue.jobLevelId;
                }
                if(this.searchValue.engageForm){
                    url += '&engageForm='+this.searchValue.engageForm;
                }
                if(this.searchValue.departmentId){
                    url += '&departmentId='+this.searchValue.departmentId;
                }
                if(this.searchValue.beginDateScope){
                    url += '&beginDateScope='+this.searchValue.beginDateScope;
                }
            }else{
                url += '&name='+this.empName;
            }
            this.getRequest(url).then(res=>{
                if(res){
                    this.total = res.total;
                    this.emps = res.data;
                    this.loading = false;
                }
            });
        },
        exportData(){
            this.downloadRequest('/employee/basic/export');
        },
        searchHandleDepNodeClick(data){
            this.inputDepName = data.name;
            this.searchValue.departmentId = data.id;
            this.visible2 = !this.visible2;
        },
        showDepView2(){
            this.visible2 = !this.visible2;
        }
    }
}
</script>

<style scoped>
    .EmpBasic .Btns{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }
    .page{
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
    }
    .department{
        width: 148px;
        display: inline-flex;
        border: 1px solid #dedede;
        height: 26px;
        border-radius: 5px;
        cursor: pointer;
        align-items: center;
        font-size: 13px;
        padding-left: 10px;
        box-sizing: border-box;
    }
    .upLoad{
        display: inline-flex;
        margin-right: 10px;
    }
    .advancedSearch{
        border: 1px solid #409eff;
        border-radius: 5px;
        box-sizing: border-box;
        padding: 5px;
        margin: 10px 0px;
        width: 100%;
    }
    .departmentAdvanced{
        width: 130px;
        display: inline-flex;
        border: 1px solid #dedede;
        height: 26px;
        border-radius: 5px;
        cursor: pointer;
        align-items: center;
        font-size: 13px;
        padding-left: 10px;
        box-sizing: border-box;
    }
    /* 可以设置不同的进入和离开动画 */
    /* 设置持续时间和动画函数 */
    .slide-fade-enter-active {
        transition: all .3s ease;
    }
    .slide-fade-leave-active {
        transition: all .3s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to
    /* .slide-fade-leave-active for below version 2.1.8 */ {
        transform: translateX(10px);
        opacity: 0;
    }
</style>